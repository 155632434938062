/* eslint-disable global-require */
/* eslint-disable import/newline-after-import */
import Common from './components/common';
import processInclude from './util/processInclude';
var ProductTile = require('./components/productTile');

document.addEventListener('DOMContentLoaded', () => {
    Common.run();

    const pt = new ProductTile();
    pt.init();

    // STOREFRONT BASE CLIENT JS
    //------------------------------------------
    processInclude(require('./util/util').widgetGoToUrl());
    processInclude(require('./base/product/wishlistHeart'));
    processInclude(require('./base/components/miniCart'));
    processInclude(require('./base/components/search'));
    processInclude(require('./base/components/clientSideValidation'));
    processInclude(require('./base/components/menu'));
});

// eslint-disable-next-line import/extensions
require('./base/components/spinner');
