/* eslint-disable indent */
/* eslint-disable lines-around-directive */
'use strict';
//var find = require('lodash/find');


function getItemListName(itemListId) {
    if (itemListId === '') return '';

    let itemListName = '';
    switch (itemListId) {
        case 'recommendated_products':
            itemListName = 'Recommendated products';
            break;

        case 'related_products':
            itemListName = 'Related products';
            break;

        case 'category_page':
            itemListName = 'Product list page';
            break;

        case 'search_result_page':
            itemListName = 'Search result page';
            break;

        case 'widget_slider':
            itemListName = 'Home page';
            break;

        case 'widget_carousel':
            itemListName = 'Widget carousel';
            break;

        default:
            break;
    }

    return itemListName;
}


function setSessionStorage(key, data) {
    sessionStorage.setItem(key, data);
}


function getSessionStorage(key) {
    return sessionStorage.getItem(key);
}

function getSessionStorageItem(pid) { // return last item by Id
    const itemsGA = sessionStorage.getItem('itemsGA');
    if (!itemsGA) {
        return;
    }
    const itemsGAObj = JSON.parse(itemsGA);
    const itemsById = itemsGAObj.filter((item) => item.item_id === pid);

    // eslint-disable-next-line consistent-return
    return itemsById[itemsById.length - 1];
    //return find(itemsGAObj, {item_id: pid});
}

function getParent(elem, className) {
    var element = elem;
    while (element !== null && element.classList.contains(className) !== true) {
        element = element.parentElement;
    }
    return element;
}

module.exports = {
    getItemListName: getItemListName,
    setSessionStorage: setSessionStorage,
    getSessionStorage: getSessionStorage,
    getSessionStorageItem: getSessionStorageItem,
    getParent: getParent,
};
