'use strict';

function loadCustomStylesStart() {
    const customStyledCategories = $('.custom-category-style');
    if (customStyledCategories && customStyledCategories.length > 0) {
        customStyledCategories.each((i) => {
            const customStyleParsed = $(customStyledCategories[i]).data('custom-style');
            if (customStyleParsed) {
                if (customStyleParsed.textColor) {
                    $(customStyledCategories[i]).children('a').css('color', customStyleParsed.textColor);
                }
                if (customStyleParsed.backgroundColor) {
                    $(customStyledCategories[i]).css('background-color', customStyleParsed.backgroundColor);
                }
            }
        });
    }
}

module.exports = function () {
    const standardStyleCategories = $('.level-top:not(.custom-category-style)');
    let defaultColor;
    let defaultBackgroundColor;

    if (standardStyleCategories && standardStyleCategories.length > 0) {
        defaultColor = $(standardStyleCategories[0]).children('a').css('color');
        defaultBackgroundColor = $(standardStyleCategories[0]).css('background-color');
    }

    loadCustomStylesStart();

    $('body').on('mouseenter', '.custom-category-style', function () {
        const customStyleParsed = $(this).data('custom-style');

        if (customStyleParsed) {
            // If not already present, set and remember the default text color
            if (!defaultColor && !customStyleParsed.textColor) {
                defaultColor = $(this).children('a').css('color');
            }
            // If not already present, set and remember the default background color
            if (!defaultBackgroundColor && !customStyleParsed.backgroundColor) {
                defaultBackgroundColor = $(this).css('background-color');
            }
            // Set custom colors on hover
            if (customStyleParsed.textColorHover) {
                $(this).children('a').css('color', customStyleParsed.textColorHover);
            }
            if (customStyleParsed.backgroundColorHover) {
                $(this).css('background-color', customStyleParsed.backgroundColorHover);
            }
        }
    });

    $('body').on('mouseleave', '.custom-category-style', function () {
        const customStyleParsed = $(this).data('custom-style');

        if (customStyleParsed) {
            if (customStyleParsed.textColor) {
                // if a custom text color is defined, set that
                $(this).children('a').css('color', customStyleParsed.textColor);
            } else if (customStyleParsed.textColorHover && defaultColor) {
                // if a custom text color was set on hover, restore the default one
                $(this).children('a').css('color', defaultColor);
            }

            if (customStyleParsed.backgroundColor) {
                // if a custom background color is defined, set that
                $(this).css('background-color', customStyleParsed.backgroundColor);
            } else if (customStyleParsed.backgroundColorHover && defaultBackgroundColor) {
                // if a custom background color was set on hover, restore the default one
                $(this).css('background-color', defaultBackgroundColor);
            }
        }
    });
};