/* eslint-disable no-eval */
/* eslint-disable comma-dangle */
//
// STOREFRONT BASE CLIENT SCRIPT
// ----------------------------------------

'use strict';

var cart = require('../cart/cart');

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            var minicartLink = $('.minicart .minicart-link');
            if ($('.minicart .minicart-quantity').length === 0) {
                minicartLink.append('<span class="minicart-quantity">' + count.quantityTotal + '</span>');
            } else {
                $('.minicart .minicart-quantity').text(count.quantityTotal);
            }

            minicartLink.attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    // OPEN MINI CART
    $('.minicart .minicart-link').on('click', function () {
        var searchContainer = $('.search-container');
        if (searchContainer.length && searchContainer.hasClass('open')) searchContainer.removeClass('open');

        var minicart = $(this).closest('.minicart');
        var minicartContent = minicart.find('.minicart-content');
        var url = minicart.data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && !minicart.hasClass('show')) {
            minicart.addClass('show');
            minicartContent.spinner().start();

            $.get(url, function (data) {
                minicartContent.empty();
                minicartContent.append(data);
                $('.minicart').trigger('minicart:reloadCouponSection', data);
                $.spinner().stop();
            });
        }
    });

    $('body').on('click', '.close-minicart', function () {
        $('.minicart').removeClass('show');
    });

    $('body').on('touchstart click', function (e) {
        var clickedElement = e.target;
        if ($('.minicart').has(clickedElement).length <= 0 && !clickedElement.closest('.modal-dialog')) {
            // Close the minicart when clicking outside of it (unless the clicked element belongs to the modal opened by the minicart)
            $('.minicart').removeClass('show');
        }
    });

    /* $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .minicart-content').removeClass('show');
    }); */

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            window.location.reload();
        }
    });

    $('.minicart').on('minicart:reloadCouponSection', function (event, data) {
        // Restyle the heights of the different parts in the minicart after changes in the coupons (to prevent overlaps between the body and the footer and make sure that the product list gets a scrollbar)
        var currentElement = event.currentTarget;
        if (currentElement.querySelector('#minicartContainer') && data.totals) {
            // Get the formulas to compute the style from the template data attributes
            var footerHeightFormula = $('#minicartContainer').data('footer-height-formula');
            var bodyHeightFormula = $('#minicartContainer').data('body-height-formula');
            var isErrorVisible = $('#invalidCouponCode').value || $('#missingCouponCode').value;
            var discounts = data.totals && data.totals.discounts ? data.totals.discounts : null;
            var activeCoupons = 0;
            var activeCouponCalloutMsgs = 0;
            var errorMessages = 0;

            if (data.error || isErrorVisible) {
                errorMessages = 1;
            }
            if (discounts && discounts.length > 0) {
                discounts.forEach(function (discount) {
                    if (discount.type === 'coupon' && discount.applied) {
                        activeCoupons += 1;
                        for (var i = 0; i < discount.relationship.length; i++) {
                            if (discount.relationship[i].callOutMsg !== '' && !discount.relationship[i].isCalloutAlreadyTracked) {
                                activeCouponCalloutMsgs += 1;
                            }
                        }
                    }
                });
            }

            // Compute the new heights
            var footerHeight = eval(footerHeightFormula.replace('activeCoupons', activeCoupons).replace('activeCouponCalloutMsgs', activeCouponCalloutMsgs).replace('errorMessages', errorMessages)).toString() + 'px';
            var bodyHeight = bodyHeightFormula.replace('footerHeight', footerHeight);

            // Assign the new heights
            currentElement.querySelector('.minicart-dynamic-style-footer').style.height = footerHeight;
            currentElement.querySelector('.minicart-dynamic-style-body').style.height = bodyHeight;
        }
    });

    $('body').on('show.bs.modal', '#removeCouponModal', function () {
        // When opening the modal from the minicart (which is not created in the header and not in the body through the decorator), place it correctly above the backdrop and restyle it properly
        if ($(this).data('from-minicart')) {
            $(this).insertAfter($('body'));
            $('.modal-title').css('font-family', '"Work Sans", sans-serif');
            $('.modal-body').css('font-family', '"Work Sans", sans-serif').css('font-size', '1rem');
        }
    });
};
