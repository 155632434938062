/* eslint-disable lines-around-directive */
'use strict';

var gaHelpers = require('../gAnalytics/gaHelpers');


class ProductTile {
    constructor() {
        this.productTiles = document.getElementsByClassName('product-tile');
        this.colorSwatches = [];

        this.onToggleColorSwatches = this.onToggleColorSwatches.bind(this);
    }

    init() {
        if (!this.productTiles) return;

        this.setup();
    }

    setup() {
        const notFiredTiles = Array.prototype.slice.call(this.productTiles).filter((item) => {
            return !item.classList.contains('firing');
        });

        const totProductTiles = notFiredTiles.length;

        if (totProductTiles === 0) return;

        for (let index = 0; index < totProductTiles; index++) {
            const productTile = notFiredTiles[index];
            const colorSwatches = productTile.querySelector('.color-swatches');

            productTile.classList.add('firing');

            productTile.addEventListener('click', this.setSessionStorageItem.bind(this, productTile));

            if (colorSwatches) {
                colorSwatches.parentIndex = index;
                this.colorSwatches.push(colorSwatches);

                this.setColorSwatches(productTile, index);
            }
        }
    }

    setColorSwatches(productTile, index) {
        const btnToggleColors = productTile.querySelector('.toggle-color-swatches');
        if (!btnToggleColors) return;
        btnToggleColors.parentIndex = index;

        btnToggleColors.addEventListener('click', this.onToggleColorSwatches);
    }

    onToggleColorSwatches(e) {
        e.preventDefault();

        const target = e.target;
        const colorSwatches = this.colorSwatches[target.parentIndex];

        if (this.hasClass(colorSwatches, 'expanded')) {
            colorSwatches.classList.remove('expanded');
        } else {
            colorSwatches.classList.add('expanded');
        }
    }

    hasClass(element, className) {
        const classes = element.className;
        // eslint-disable-next-line no-unneeded-ternary
        return classes.indexOf(className) === -1 ? false : true;
    }


    setSessionStorageItem(tile) {
        gaHelpers.setSessionStorageItem(tile);
    }
}

//export default ProductTile;
module.exports = ProductTile;
