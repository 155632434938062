/* eslint-disable no-underscore-dangle */
/* eslint-disable lines-around-directive */
/* eslint-disable quotes */
/* eslint-disable indent */

//var _find = require('lodash/find');

class DataLayer {
    constructor(props) {
        this.props = props;
        this.contextType = props.contextType;
        this.actionType = props.action;
        this.dataLayerItem = typeof props.dataLayerItem === 'string' || props.dataLayerItem instanceof String ? JSON.parse(props.dataLayerItem) : props.dataLayerItem;
    }

    init() {
        switch (this.contextType) {
            case 'checkout_page':
                this.checkout();
                break;

            case 'custom_dataLayer':
                this.customDataLayer();
                break;

            default:
                this.setProductVariant();
                break;
        }
        /*if (this.contextType === 'checkout_page') {
            this.checkout();
        } else {
            this.setProductVariant();
        }*/
    }


    setProductVariant() {
        window.dataLayer.push({ ecommerce: null });

        const newDataLayer = {
            event: this.actionType,
            ecommerce: {
                items: [this.dataLayerItem],
            },
        };

        switch (this.actionType) {
            case 'add_to_wishlist':
                newDataLayer.ecommerce.currency = this.dataLayerItem.currency;
                newDataLayer.ecommerce.value = this.dataLayerItem.price;
                break;
            default:
                break;
        }

        window.dataLayer.push(newDataLayer);
    }


    checkout() {
        const {action, paymentType} = this.props;

        window.dataLayer.push({ ecommerce: null });

        const newDataLayer = {
            event: action,
            ecommerce: {
                currency: this.dataLayerItem.currency,
                value: this.dataLayerItem.total,
                coupon: this.dataLayerItem.coupons && this.dataLayerItem.coupons.coupon ? this.dataLayerItem.coupons.coupon : '',
                items: this.dataLayerItem.items,
            },
        };

        if (this.dataLayerItem.coupons) {
            //Object.assign(newDataLayer.ecommerce, this.dataLayerItem.coupons);
            sessionStorage.setItem('couponsGA', JSON.stringify(this.dataLayerItem.coupons)); // for purchase event ga
        }

        switch (action) {
            case 'add_shipping_info':
                newDataLayer.ecommerce.value = this.dataLayerItem.shippingCost;
                newDataLayer.ecommerce.shipping_tier = this.dataLayerItem.shipping_tier;
                break;

            case 'add_payment_info':
                newDataLayer.ecommerce.payment_type = paymentType !== '' ? paymentType : this.dataLayerItem.payment_type;
                break;

            default:
                break;
        }

        window.dataLayer.push(newDataLayer);
    }


    // Newsletter subscription,
    // per login, sign up guardare app_camomilla/cartridge/templates/default/common/gAnalytics/dataLayer.isml
    customDataLayer() {
        const {userID, emailMD5, emailSHA256} = this.props.userData;
        let userId = userID;

        if (userId === '') {
            userId = window.gaUserData && window.gaUserData.userID ? window.gaUserData.userID : '';
        }

        const dataLayer = {
            event: this.actionType,
            userID: userId,
        };

        if (this.actionType === 'newsletter_success') {
            dataLayer.newsletter_type = 'Modal';
            dataLayer.emailMD5 = emailMD5;
            dataLayer.emailSHA256 = emailSHA256;
        }

        window.dataLayer.push(dataLayer);
    }
}

module.exports = DataLayer;
