import {hasClass, stringToArrayOfObjects} from '../util/util';

class SetSlickSlider {
    constructor() {
        this.sliders = document.getElementsByClassName('slider');
    }

    init() {
        if (!this.sliders) return;

        this.setup();
    }

    setup() {
        const totSlider = this.sliders.length;

        for (let index = 0; index < totSlider; index++) {
            const slider = this.sliders[index];
            let autoplay = slider.getAttribute('data-autoplay');
            if (autoplay) {
                if (autoplay.length === 0) {
                    autoplay = false;
                }
            } else {
                autoplay = false;
            }

            const props = {
                dots: slider.getAttribute('data-dots'),
                arrows: slider.getAttribute('data-arrows'),
                infinite: slider.getAttribute('data-infinite'),
                autoplay: autoplay,
                slidestoshow: slider.getAttribute('data-slidestoshow'),
                slidestoscroll: slider.getAttribute('data-slidestoscroll'),
                responsive: slider.getAttribute('data-responsive') ? slider.getAttribute('data-responsive') : null,
            };

            $(slider).on('init', () => {
                this.setSlickArrowsPosition();
            });

            if (hasClass(slider, 'carousel') || Number(props.slidestoshow ? props.slidestoshow : 0) > 1) {
                this.initCarousel(slider, props);
            } else {
                this.initSlider(slider, props);
            }
        }
    }

    initSlider(slider, props) {
        const sliderElem = $(slider);

        sliderElem.on('init', function (event, slick) {
            const totSlides = slick.$slides.length;

            for (let index = 0; index < totSlides; index++) {
                const slide = slick.$slides[index];
                if (slide) {
                    slide.setAttribute('data-item-index', index);
                }
            }
        });

        sliderElem.slick({
            dots: props.dots ? JSON.parse(props.dots) : true,
            arrows: props.arrows ? JSON.parse(props.arrows) : false,
            speed: 300,
            autoplay: props.autoplay ? JSON.parse(props.autoplay) : false,
            autoplaySpeed: 4000,
            //prevArrow: this.prevArrow,
            //nextArrow: this.nextArrow,
            accessibility: true,
        });
    }

    initCarousel(slider, props) {
        let responsive = [];
        const carousel = $(slider);
        if (props.responsive) {
        /*  let newJson = props.responsive.replace(/'/g, '"');
            newJson = newJson.replace(/([^"]+)|("[^"]+")/g, ($0, $1, $2) => {
                if ($1) {
                    return $1.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
                }
                return $2;
            }); */
            responsive = stringToArrayOfObjects(props.responsive);
        }

        carousel.on('init', function (event, slick) {
            const totSlides = slick.$slides.length;

            for (let index = 0; index < totSlides; index++) {
                const slide = slick.$slides[index];
                const productTile = slide.getElementsByClassName('product-tile')[0];

                if (productTile) {
                    productTile.setAttribute('data-item-index', index);
                }
            }
        });

        carousel.slick({
            dots: props.dots ? JSON.parse(props.dots) : true,
            arrows: props.arrows ? JSON.parse(props.arrows) : false,
            infinite: props.infinite ? JSON.parse(props.infinite) : false,
            slidesToShow: props.slidestoshow ? Number(props.slidestoshow) : 4,
            slidesToScroll: props.slidestoscroll ? Number(props.slidestoscroll) : 4,
            speed: 300,
            //prevArrow: this.prevArrow,
            //nextArrow: this.nextArrow,
            accessibility: true,
            responsive,
        });
    }

    setSlickArrowsPosition() {
        const totSlider = this.sliders.length;

        for (let index = 0; index < totSlider; index++) {
            const slider = this.sliders[index];
            const arrows = slider.getElementsByClassName('slick-arrow');

            if (arrows.length !== 2) continue;

            const firstSlide = slider.getElementsByClassName('slick-slide')[0];
            const img = firstSlide.getElementsByTagName('img')[0];

            if (!img) continue;

            const posArrows = img.getBoundingClientRect().height / 2;

            if (posArrows === 0) continue;

            arrows[0].style.setProperty('top', `${posArrows}px`);
            arrows[1].style.setProperty('top', `${posArrows}px`);
        }
    }
}

export default SetSlickSlider;
