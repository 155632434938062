/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable space-before-blocks */

/* How to work
HMTL:
<div class="toggle-pwd-container">
    <input type="password" />
    <button type="button" class="button-icon toggle-pwd">
        <isicon name="hidden" cl="icon-hidden" />
        <isicon name="visible" cl="icon-visible" />
    </button>
</div>
*/

class TogglePassword {
    constructor(){
        //this.init = this.init.bind(this);
        this.toggleHandler = this.toggleHandler.bind(this);
    }

    init(){
        const toggleBtn = document.getElementsByClassName('toggle-pwd');

        if (!toggleBtn) return;

        const totBtn = toggleBtn.length;

        for (let i = 0; i < totBtn; i++){
            toggleBtn[i].addEventListener('click', this.toggleHandler);
        }
    }

    toggleHandler(e){
        e.stopPropagation();
        e.preventDefault();
        const target = e.target.tagName !== 'BUTTON' ? e.currentTarget : e.target;
        const pwd = target.previousElementSibling;
        const isTxt = pwd.getAttribute('type') === 'text';

        isTxt ?
        (pwd.setAttribute('type', 'password'), target.classList.remove('visible')) :
        (pwd.setAttribute('type', 'text'), target.classList.add('visible'));
    }
}

export default TogglePassword;
