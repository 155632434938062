/* eslint-disable comma-dangle */
/* eslint-disable no-restricted-globals */
/* eslint-disable indent */
/* eslint-disable lines-around-directive */

//
// STOREFRONT BASE CLIENT SCRIPT
// ----------------------------------------
'use strict';

var notifyme = require('../../util/notifyme');
var gaHelpers = require('../../gAnalytics/gaHelpers');


module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.add-to-wishlist', function (e) {
            e.preventDefault();
            var button = $(this);
            var pid = null;
            var url = button.data('href');
            var optionId = null;
            var optionVal = null;
            var productDetails = null;
            var wishlistHead = $('.header-content .wishlist');

            switch (true) {
                case button.hasClass('plp'):
                    productDetails = button.closest('.product-detail');
                    pid = button.closest('.product').data('pid');
                    optionId = productDetails.find('.product-option').attr('data-option-id') || null;
                    optionVal = productDetails.find('.options-select option:selected').attr('data-value-id') || null;
                    break;

                case button.hasClass('pdp'):
                    productDetails = button.closest('.product-detail');
                    pid = productDetails.data('pid');
                    optionId = productDetails.find('.product-option').attr('data-option-id') || null;
                    optionVal = productDetails.find('.options-select option:selected').attr('data-value-id') || null;
                    break;

                case button.hasClass('minicart'):
                case button.hasClass('cart'):
                    pid = button.closest('.product-line-toolbar').data('pid');
                    optionId = button.closest('.product-info').find('.lineItem-options-values').data('option-id');
                    optionVal = button.closest('.product-info').find('.lineItem-options-values').data('value-id');
                    break;

                default:
                    break;
            }

            if (!url || !pid) {
                return;
            }

            // Google Analytics: set props
            var props = {
                action: 'add_to_wishlist',
                qty: 1,
            };

            $.spinner().start();

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal,
                },
                success: function (data) {
                    $.spinner().stop();
                    notifyme.setMessage(data.msg, 'success');
                    button.addClass('is-in-wishlist');

                    if (!data.error) { // !error: The product is already in the wishlist
                        // Google Analytics: set dataLayer
                        gaHelpers.setProductDataLayer(data, props);
                    }

                    if (!data.emptyWishlist) {
                        wishlistHead.addClass('wishlist-added');
                    }
                },
                error: function (err) {
                    $.spinner().stop();
                    notifyme.setMessage(err.msg, 'error', {autoClose: false});
                },
            });
        });
    },
};
