/* globals grecaptcha */
/* eslint-disable no-restricted-syntax */
import { Modal } from 'bootstrap';
import { getCookie, setCookie, getParent } from '../util/util';
import DataLayer from '../gAnalytics/gaDataLayer';

class Newsletter {
    constructor() {
        this.openModalBtns = document.querySelectorAll('.subscribe-email');
        this.modal = document.getElementById('newsletterModal');
        this.currentModal = this.modal && new Modal(this.modal, {
            keyboard: false,
        });
        this.modalOpeningTime = this.modal && this.modal.getAttribute('data-opening-time') * 1000;
        this.modalEnabled = this.modal && this.modal.getAttribute('data-newsletter-is-enabled');
        this.modalCookieDuration = this.modal && this.modal.getAttribute('data-newsletter-cookie-duration');
        this.isCustomerSubscribed = this.modal && this.modal.getAttribute('data-is-customer-subscribed');
        this.modalTimeout = null;
        this.subscribeBtn = document.getElementById('subscribeBtn');
        this.backToSubscriptionForm = document.getElementById('backToSubscriptionForm');
        this.newsletterSubscriptionForm = document.getElementById('newsletterSubscriptionForm');
        this.recaptchaKey = document.getElementById('recaptcha-key') ? document.getElementById('recaptcha-key').getAttribute('data-recaptcha') : null;

        this.subscribe = this.subscribe.bind(this);
        this.validationForm = this.validationForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.showSuccessMessage = this.showSuccessMessage.bind(this);
        this.showErrorMessage = this.showErrorMessage.bind(this);
    }

    init() {
        if (!this.openModalBtns.length && !this.modal && !this.subscribeBtn) return;

        this.initModal();
        this.showModal();

        this.subscribeBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.subscribe();
        });

        this.backToSubscriptionForm.addEventListener('click', (e) => {
            e.preventDefault();
            this.showForm();
        });
    }

    initModal() {
        for (var i = 0; i < this.openModalBtns.length; ++i) {
            var btn = this.openModalBtns[i];
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                this.resetForm();
                this.showForm();
                this.currentModal.show();

                if (this.modalTimeout) {
                    clearTimeout(this.modalTimeout);
                    if (!getCookie('show-newsletter')) {
                        setCookie('show-newsletter', 1, this.modalCookieDuration);
                    }
                }
            });
        }
    }

    showModal() {
        // skip if customer is already subscribed
        if (this.modalEnabled !== 'true' || this.isCustomerSubscribed === 'true') {
            return;
        }

        if (!getCookie('show-newsletter')) {
            this.modalTimeout = setTimeout(() => {
                this.resetForm();
                this.showForm();
                this.currentModal.show();
                setCookie('show-newsletter', 1, this.modalCookieDuration);
            }, this.modalOpeningTime);
        }
    }

    subscribe() {
        const $this = this;
        const form = $this.newsletterSubscriptionForm;
        const url = $this.subscribeBtn.getAttribute('data-href');
        const nlFistName = form.querySelector('#nlFistName').value;
        const nlLastName = form.querySelector('#nlLastName').value;
        const nlEmail = form.querySelector('#emailId').value;
        const privacyAgreement = form.querySelector('#privacyAgreement').checked ? 1 : 0;
        const modalContent = $this.modal.querySelector('.modal-body');
        $(modalContent).spinner().start();
        grecaptcha.execute($this.recaptchaKey, { action: 'newsletter' }).then(function (token) {
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    emailId: nlEmail,
                    firstName: nlFistName,
                    lastName: nlLastName,
                    privacyAgreement,
                    token: token,
                },
                success: function (data) {
                    $(modalContent).spinner().stop();
                    if (!data.success) {
                        $this.validationForm(data);
                    } else {
                        $this.showSuccessMessage();
                        // Google Analytics: set dataLayer
                        var dl = new DataLayer({contextType: 'custom_dataLayer', action: 'newsletter_success', userData: data.userCryptoData});
                        dl.customDataLayer();
                    }
                },
                error: function () {
                    $this.showErrorMessage();
                    $(modalContent).spinner().stop();
                },
            });
        });
    }

    validationForm(data) {
        const requiredFlds = this.newsletterSubscriptionForm.querySelectorAll('[required]');
        const fields = data.fields ? data.fields : [];
        const totFlds = fields.length;

        for (const fld of requiredFlds) {
            const parent = getParent(fld, 'DIV');
            const feedback = parent.getElementsByClassName('invalid-feedback')[0];
            feedback.innerHTML = '';
            fld.classList.remove('is-invalid');

            if (totFlds > 0) {
                for (let index = 0; index < totFlds; index++) {
                    const f = fields[index];
                    if (fld.name in f) {
                        feedback.innerHTML = f[fld.name];
                        fld.classList.add('is-invalid');
                    }
                }
            }
        }
    }


    resetField(fld) {
        const parent = getParent(fld, 'DIV');
        const feedback = parent.getElementsByClassName('invalid-feedback')[0];
        feedback.innerHTML = '';
        fld.classList.remove('is-invalid');
    }


    resetForm() {
        const form = this.newsletterSubscriptionForm;
        const formDataKeys = new FormData(form).keys();

        for (const key of formDataKeys) {
            const fld = form.querySelector('[name=' + key + ']');
            this.resetField(fld);
            fld.value = '';
            if (fld.checked) fld.checked = false;
        }
    }

    showSuccessMessage() {
        const modal = this.modal;
        modal.classList.remove('content-full-width');
        modal.querySelector('.form-container').style.setProperty('display', 'none');
        modal.querySelector('.image-container').style.setProperty('display', 'none');
        modal.querySelector('.success-container').style.setProperty('display', '');
        modal.querySelector('.close').classList.remove('white-close');
    }

    showErrorMessage() {
        const modal = this.modal;
        modal.classList.remove('content-full-width');
        modal.querySelector('.form-container').style.setProperty('display', 'none');
        modal.querySelector('.image-container').style.setProperty('display', 'none');
        modal.querySelector('.error-container').style.setProperty('display', '');
        modal.querySelector('.close').classList.remove('white-close');
    }

    showForm() {
        const modal = this.modal;
        modal.classList.add('content-full-width');
        modal.querySelector('.success-container').style.setProperty('display', 'none');
        modal.querySelector('.error-container').style.setProperty('display', 'none');
        modal.querySelector('.form-container').style.setProperty('display', '');
        modal.querySelector('.image-container').style.setProperty('display', '');
        modal.querySelector('.close').classList.add('white-close');
    }
}

export default Newsletter;
//new Newsletter().init();
