/* eslint-disable indent */
/* eslint-disable no-useless-escape */
/* eslint-disable valid-jsdoc */
const appendToUrl = (url, params) => {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return `${key}=${encodeURIComponent(params[key])}`;
    }).join('&');
    return newUrl;
};


/**
 * Scroll to the top of the specified element
 *
 * @param {DOM element} element element where to scroll
 * @param {DOM element} header possible header of the page
 */
function scrollToElement(element, header) {
    $(window).on('load', () => {
        try {
            if (element) {
                window.scroll(0, 0);
                const dimension = header ? ($(element).offset().top - header) : $(element).offset().top;
                $('html,body').animate({
                    scrollTop: dimension,
                }, 'slow');
            }
        } catch (e) {
            //console.debug(e.toString());
        }
    });
}

/**
 * set the top padding to the page container
 * */
function setPageTopPadding() {
    const headerHeight = $('.page-header').outerHeight();
    $('.page').css('padding-top', headerHeight);
}

const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    document.cookie = `${cname}=${cvalue}; expires=${d.toUTCString()}; path=/`;
};

const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};


const getUrlParameterByName = (name, url) => {
    const nameRep = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp(`[?&]${nameRep}(=([^&#]*)|&|#|$)`);
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const gridBreakpoints = (val) => {
/*     xs: 0,
    sm: 576px,  // small: 0
    md: 768px,  // medium: 640px
    lg: 1024px, // large: 1024px
    xl: 1280px, // xlarge: 1280px
    xxl: 1440px // xxlarge: 1440px
 */
    switch (val) {
        case 'small':
        case 'sm':
            return 576;
        case 'medium':
        case 'md':
            return 768;
        case 'large':
        case 'lg':
            return 1024;
        case 'extra large':
        case 'xl':
            return 1280;
        case 'super large':
        case 'xxl':
            return 1440;
        default:
            return 0;
    }
};

const mediaQuery = (breakpoint) => { // Foundation.MediaQuery.is, DA VERIFICARE END POINTS
    let mq = false;
    switch (breakpoint) {
        case 'small':
            mq = window.matchMedia(`(min-width: ${gridBreakpoints('sm')}px)`).matches;
            return mq;
        case 'medium':
            mq = window.matchMedia(`(min-width: ${gridBreakpoints('md')}px)`).matches;
            return mq;
        case 'large':
            mq = window.matchMedia(`(min-width: ${gridBreakpoints('lg')}px)`).matches;
            return mq;
        case 'extra large':
            mq = window.matchMedia(`(min-width: ${gridBreakpoints('xl')}px)`).matches;
            return mq;
        case 'tablet':
            mq = window.matchMedia(`(max-width: ${gridBreakpoints('lg')}px)`).matches;
            return mq;
        default:
            return false;
    }
};

const hasClass = (element, className) => {
    const classes = element.className;
    // eslint-disable-next-line no-unneeded-ternary
    return classes.indexOf(className) === -1 ? false : true;
};


const getParent = (elem, tagName) => {
    var element = elem;
    while (element.tagName !== tagName) {
        element = element.parentElement;
    }
    return element;
};

const stringToArrayOfObjects = (str) => {
    let newJson = str.replace(/'/g, '"');
    newJson = newJson.replace(/([^"]+)|("[^"]+")/g, ($0, $1, $2) => {
        if ($1) {
            return $1.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
        }
        return $2;
    });
    return JSON.parse(newJson);
};


function fireEvent(element, event) {
    let e = null;
    if (document.createEventObject) { // for IE
        e = document.createEventObject();
        element.fireEvent('on' + event, e);
    } else {
        e = new Event(event);
        element.dispatchEvent(e);
    }
}

function widgetGoToUrl() {
    $('[data-link]').on('click', function () {
        window.location = $(this).attr('data-link');
    });
}


export {
    appendToUrl,
    scrollToElement,
    setPageTopPadding,
    setCookie,
    getCookie,
    getUrlParameterByName,
    gridBreakpoints,
    mediaQuery,
    hasClass,
    getParent,
    stringToArrayOfObjects,
    fireEvent,
    widgetGoToUrl,
};
