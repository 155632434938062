/* eslint-disable indent */
/* eslint-disable lines-around-directive */
/* eslint-disable import/newline-after-import */
//
// STOREFRONT BASE CLIENT SCRIPT
// ----------------------------------------
'use strict';

var debounce = require('lodash/debounce');
var suggestionWrapper = $('#suggestions-wrapper');
var endpoint = suggestionWrapper.data('url');
var minChars = 1;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsContent() {
    //return $(scope).siblings('.suggestions-wrapper');
    return $('#suggestions-wrapper__content');
}


/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsContent = getSuggestionsContent().empty();

    $suggestionsContent.spinner().stop();

    if (typeof (response) !== 'object') {
        $suggestionsContent.append(response).show();
        $(this).siblings('.reset-button').addClass('show');

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.suggestions .item');
        if ($(suggestionsList).length) {
            $('input.search-field').attr('aria-describedby', 'search-result-count');
        } else {
            $('input.search-field').removeAttr('aria-describedby');
        }
    } else {
        suggestionWrapper.hide();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        suggestionWrapper.show();
        getSuggestionsContent().spinner().start();

        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                getSuggestionsContent().spinner().stop();
            },
        });
    } else {
        $(scope).siblings('.reset-button').removeClass('show');
        suggestionWrapper.hide();
        getSuggestionsContent().empty();
    }
}


module.exports = function () {
    $('form[name="simpleSearch"]').submit(function (e) {
        if ($(this).find('.search-field').val().length < 1) {
            e.preventDefault();
        } else {
            var suggestionsList = $('.suggestions .item');
            if (suggestionsList.filter('.selected').length !== 0) {
                e.preventDefault();
                suggestionsList.filter('.selected').find('a')[0].click();
            }
        }
    });

    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup', function (e) {
            e.stopPropagation();
            debounceSuggestions(this, e);
        });
    });

    $('body').on('click', '#toggle-mobile-search', (e) => {
        e.preventDefault();
        const $searchContainer = $('.search-container');
        if ($searchContainer.hasClass('open')) {
            $searchContainer.removeClass('open');
        } else {
            $searchContainer.addClass('open');
            $searchContainer.find('input').trigger('focus');
        }
    });

    $('.search-container .reset-button').on('click', function () {
        $(this).removeClass('show');
        suggestionWrapper.hide();
        getSuggestionsContent().empty();
    });
};
