function createNotify(msg, index, type, opts) {
    var container = document.createElement('div');
    var wrapper = document.createElement('div');
    var progress = document.createElement('progress');
    var closeBtn = document.createElement('button');

    container.className = 'notifyme ' + type;
    wrapper.className = 'notifyme-wrapper';
    progress.setAttribute('value', 0);
    progress.setAttribute('max', 10);
    progress.setAttribute('id', 'progressBar-' + index);
    closeBtn.className = 'button-icon close';

    wrapper.innerHTML = msg;
    container.append(closeBtn);
    container.append(wrapper);
    container.append(progress);

    if (opts.positionY > 0) {
        container.style.setProperty('top', opts.positionY + 'px');
    }

    document.body.append(container);


    var progressTimer = setInterval(() => {
        progress.value++;

        if (progress.value >= progress.max) {
            clearInterval(progressTimer);
            if (opts.autoClose) {
                container.remove();
            }
        }
    }, opts.closeTime);

    closeBtn.addEventListener('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        container.remove();
    });
}

/**
 * type: 'success' || 'error' || 'warning'
 * default options: {autoClose: true, closeTime: 200, positionY: 0}
 */

module.exports = {
    setMessage: function (message, type = 'success', options = {}) {
        var notifyElems = document.getElementsByClassName('notifyme') || [];
        /* eslint-disable prefer-object-spread */
        var opts = Object.assign({autoClose: true, closeTime: 200, positionY: 0}, options);

        if (notifyElems.length > 0) {
            var rect = notifyElems[notifyElems.length - 1].getBoundingClientRect();
            opts.positionY = rect.bottom + 5;
            createNotify(message, notifyElems.length, type, opts);
        } else {
            createNotify(message, 0, type, opts);
        }
    },
};
