/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable lines-around-directive */
'use strict';

//var find = require('lodash/find');
var gaUtils = require('./gaUtils');
var DataLayer = require('./gaDataLayer');


function setProductDataLayer(data, props) {
    const defaultProps = {action: '', contextType: 'some_page'};
    const gaProps = {...defaultProps, ...props};

    // Google Analytics: set dataLayer
    if (data.dataLayerItem) {
        const dataLayerItem = JSON.parse(data.dataLayerItem);
        const ssItem = gaUtils.getSessionStorageItem(dataLayerItem.item_id);
        if (!ssItem) {
            return;
        }

        if (ssItem.pid) delete ssItem.pid;

        let cartType = {};
        if (gaProps.cartType) {
            cartType = {cart_type: gaProps.cartType};
        }

        const newDataItem = {
            ...dataLayerItem,
            quantity: gaProps.qty ? Number(gaProps.qty) : 1,
            ...ssItem,
            ...cartType,
        };

        const gaDataLayer = new DataLayer({...props, dataLayerItem: newDataItem});
        gaDataLayer.init();
    }
}

function setProductActionCartDataLayer(data, props, itemId) {
    const defaultProps = {action: '', contextType: 'some_page'};
    const gaProps = {...defaultProps, ...props};

    // Google Analytics: set dataLayer
    if (data.dataLayerItem) {
        const dataLayerItem = JSON.parse(data.dataLayerItem);
        const ssItem = gaUtils.getSessionStorageItem(itemId || dataLayerItem.item_id);
        if (!ssItem) {
            return;
        }

        if (ssItem.pid) delete ssItem.pid;

        let cartType = {};
        if (gaProps.cartType) {
            cartType = {cart_type: gaProps.cartType};
        }

        var newDataItem = dataLayerItem;
        if (ssItem.item_list_id) {
            newDataItem.item_list_id = ssItem.item_list_id;
        }

        if (ssItem.item_list_name) {
            newDataItem.item_list_name = ssItem.item_list_name;
        }

        newDataItem = {
            ...newDataItem,
            quantity: gaProps.qty ? Number(gaProps.qty) : 1,
            ...cartType,
        };

        const gaDataLayer = new DataLayer({...props, dataLayerItem: newDataItem});
        gaDataLayer.init();
    }
}

function setCheckoutDataLayer(data, props) {
    const itemsGA = sessionStorage.getItem('checkoutItemsGA');

    // Google Analytics: set dataLayer
    if (data.dataLayerItem && itemsGA) {
        const dataLayerItem = JSON.parse(data.dataLayerItem);

        const checkoutData = {
            currency: dataLayerItem.currency,
            total: dataLayerItem.total,
            shippingCost: dataLayerItem.shippingCost,
            shipping_tier: data.shippingMethod ? data.shippingMethod : '',
            payment_type: data.paymentMethod ? data.paymentMethod.value : '',
            items: JSON.parse(itemsGA),
        };
        //items: dataLayerItem.productItems,

        if (dataLayerItem.coupons) {
            checkoutData.coupons = dataLayerItem.coupons;
        }

        const gaDataLayer = new DataLayer({...props, dataLayerItem: checkoutData});
        gaDataLayer.init();
    }
}

function setSessionStorageItem(tile) {
    let itemIndex = tile.getAttribute('data-item-index');
    itemIndex = itemIndex ? Number(itemIndex) : 0;
    const pid = tile.getAttribute('data-pid');
    const categoryID = document.getElementById('category_id');
    const itemListElem = categoryID || gaUtils.getParent(tile, 'products-carousel') || gaUtils.getParent(tile, 'wg');
    const itemListId = (itemListElem && (itemListElem.value || itemListElem.getAttribute('data-list-type'))) || 'search_result_page';
    const itemListName = gaUtils.getItemListName(categoryID ? 'category_page' : itemListId);
    const itemsGA = gaUtils.getSessionStorage('itemsGA');

    if (!itemsGA) {
        gaUtils.setSessionStorage('itemsGA', JSON.stringify([{item_id: pid, index: itemIndex, item_list_id: itemListId, item_list_name: itemListName}]));
    } else {
        const itemsGAObj = JSON.parse(itemsGA);
        /*const item = find(itemsGAObj, {item_id: pid});

        if (item) {
            const newItemsGAObj = [];
            const totItems = itemsGAObj.length;
            const newItem = [{...item, index: itemIndex, item_list_id: itemListId, item_list_name: itemListName}];

            for (let i = 0; i < totItems; i++) {
                newItemsGAObj.push({
                    ...itemsGAObj[i],
                    ...(newItem.find((itm) => itm.item_id === itemsGAObj[i].item_id))}
                );
            }

            gaUtils.setSessionStorage('itemsGA', JSON.stringify(newItemsGAObj));
        } else {*/
            itemsGAObj.push({item_id: pid, index: itemIndex, item_list_id: itemListId, item_list_name: itemListName});
            gaUtils.setSessionStorage('itemsGA', JSON.stringify(itemsGAObj));
        //}
    }
}

function updateSessionItemAfterAddToCart(dataLayerItem, variantId) { // after add_to_cart
    if (!dataLayerItem || !variantId) return;

    const itemsGA = gaUtils.getSessionStorage('itemsGA');
    if (!itemsGA) {
        return;
    }
    const itemsGAObj = JSON.parse(itemsGA);
    const dataLayerItemObj = JSON.parse(dataLayerItem);
    const itemsById = itemsGAObj.filter((item) => item.item_id === dataLayerItemObj.item_id);
    const lastItemById = itemsById[itemsById.length - 1];
    if (!lastItemById) {
        return;
    }

    lastItemById.pid = variantId;
    gaUtils.setSessionStorage('itemsGA', JSON.stringify(itemsGAObj));
}


function updateSessionItemAfterRemoveFromCart(dataLayerItem, variantId) { // after remove_from_cart
    if (!dataLayerItem) return;

    const itemsGA = gaUtils.getSessionStorage('itemsGA');
    if (!itemsGA) {
        return;
    }
    const itemsGAObj = JSON.parse(itemsGA);
    const newItemsGAObj = itemsGAObj.filter((item) => item.item_id !== dataLayerItem.item_id && item.pid !== variantId);

    gaUtils.setSessionStorage('itemsGA', JSON.stringify(newItemsGAObj));
}


module.exports = {
    setProductDataLayer: setProductDataLayer,
    setProductActionCartDataLayer: setProductActionCartDataLayer,
    setCheckoutDataLayer: setCheckoutDataLayer,
    setSessionStorageItem: setSessionStorageItem,
    updateSessionItemAfterAddToCart: updateSessionItemAfterAddToCart,
    updateSessionItemAfterRemoveFromCart: updateSessionItemAfterRemoveFromCart,
};
