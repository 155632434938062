/* eslint-disable indent */
import Nav from './nav';
import Footer from './footer';
import SetSlickSlider from './setSlickSlider';
//import ProductTile from './productTile';
import TogglePassword from './togglePassword';
import Newsletter from './newsletter';
import {setPageTopPadding} from '../util/util';

class Common {
    constructor() {
        this.slickSlider = null;
        this.isMobile = window.innerWidth >= 1024 ? false : true;
        this.viewportChanged = false;
        this.screenWidth = 0;
        this.screenHeight = 0;
        this.resolutions = {
            large: 1280,
            medium: 1024,
            small: 768,
        };
    }

    run() {
        new Nav().init();
        new Footer().init();
        this.slickSlider = new SetSlickSlider();
        this.slickSlider.init();
        //new ProductTile().init();
        new TogglePassword().init();
        new Newsletter().init();

        switch (this.isMobile) {
            case true:
                this.setupSmallScreen();
                break;

            case false:
                this.setupLargeScreen();
                break;
        }

        window.addEventListener('resize', this.resize.bind(this, window));
    }

    setupSmallScreen() {
        //this.destroyLargeScreen();
        setPageTopPadding();
    }

    setupLargeScreen() {
        //this.destroySmallScreen();
        setPageTopPadding();
    }

    destroySmallScreen() {}

    destroyLargeScreen() {}


    resize(win) {
        const ww = win.innerWidth;
        const wh = win.innerHeight;

        if (this.screenWidth !== ww || this.screenHeight !== wh) {
            this.screenHeight = wh;
            this.screenWidth = ww;

            if (this.viewportChanged === false && this.isMobile && this.screenWidth >= this.resolutions.medium) {
                this.isMobile = false;
                this.viewportChanged = true;
            }
            if (this.viewportChanged === false && !this.isMobile && this.screenWidth < this.resolutions.medium) {
                this.isMobile = true;
                this.viewportChanged = true;
            }

            if (this.viewportChanged === true) {
                switch (this.isMobile) {
                    case true:
                        this.setupSmallScreen();
                        break;

                    case false:
                        this.setupLargeScreen();
                        break;
                    }

                this.viewportChanged = false;
            }

            this.slickSlider.setSlickArrowsPosition();
        }
    }
}

const common = new Common();
export default common;
