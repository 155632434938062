import {Collapse} from 'bootstrap';
import {mediaQuery} from '../util/util';

class Footer {
    constructor() {
        this.footerItemsContainer = document.getElementsByClassName('footer-item');
        this.footerItems = [];
        this.openers = [];
        this.collapsibles = [];

        this.handlerToggle = this.handlerToggle.bind(this);
    }

    init() {
        this.setAccordionMobile();
    }

    setAccordionMobile() {
        /*  if (!mediaQuery('tablet') || this.footerItems.length === 0) {
            return;
        } */

        const totItems = this.footerItemsContainer.length;
        for (let index = 0; index < totItems; index++) {
            var itemObj = this.footerItemsContainer[index];
            var subitem = itemObj.querySelectorAll('.footer-item-collapse');
            if (subitem && subitem.length > 0) {
                for (let j = 0; j < subitem.length; j++) {
                    this.footerItems.push(subitem[j]);
                }
            } else {
                this.footerItems.push(itemObj);
            }
        }

        for (let index = 0; index < this.footerItems.length; index++) {
            var item = this.footerItems[index];
            const opener = item.getElementsByClassName('opener')[0];
            const collapsibles = item.getElementsByClassName('collapsible')[0];

            if (!opener || !collapsibles) continue;

            opener.parentIndex = index;
            collapsibles.parentIndex = index;

            opener.collapse = new Collapse(collapsibles, {
                toggle: false,
                show: true,
                hide: true,
            });

            opener.addEventListener('click', this.handlerToggle);

            this.openers.push(opener);
            this.collapsibles.push(collapsibles);
        }
    }

    handlerToggle(e) {
        if (!mediaQuery('tablet') || this.footerItems.length === 0) {
            return;
        }
        e.preventDefault();
        const target = e.target;
        const parentIndex = target.parentIndex;
        const opener = this.openers[parentIndex];
        const state = this.collapsibles[parentIndex].className.indexOf('show');

        if (state === -1) { // it's close
            opener.collapse.show();
            opener.setAttribute('aria-expanded', true);
            this.footerItems[parentIndex].classList.add('expanded');
        } else { // it's open
            opener.collapse.hide();
            opener.setAttribute('aria-expanded', false);
            this.footerItems[parentIndex].classList.remove('expanded');
        }
    }
}

export default Footer;
