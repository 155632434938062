/* eslint-disable no-unused-expressions */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable indent */
import {hasClass, getParent} from '../util/util';

class Nav {
    constructor() {
        this.nav = document.getElementById('primary-menubar');
        this.navItems = [];
        this.subMenu = [];
        this.toggleNavButton = document.getElementById('open-mobile-nav');
        this.toggleSearch = document.getElementById('toggle-mobile-search'); // for mobile
        this.body = document.getElementsByTagName('body')[0];
        this.header = document.getElementsByTagName('header')[0];
        this.isMobile = window.innerWidth >= 1024 ? false : true;
        this.viewportChanged = false;
        this.screenWidth = 0;
        this.screenHeight = 0;
        this.resolutions = {
            large: 1280,
            medium: 1024,
            small: 768,
        };
        this.eventType = null;

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onToggleMenubar = this.onToggleMenubar.bind(this);
        this.onToggleNavItem = this.onToggleNavItem.bind(this);
        this.handlerToggleSearch = this.handlerToggleSearch.bind(this);
    }

    init() {
        if (!this.nav) return;
        if (hasClass(this.header, 'headernomenu')) return;

        const touch = ('ontouchstart' in window) || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        this.eventType = (touch) ? 'touchend' : 'mouseenter';
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;

        window.addEventListener('resize', this.resize.bind(this, window));

        this.setup();
    }


    setup() {
        if (!this.nav) return;
        const topLevelItems = this.nav.getElementsByClassName('level-top');
        const totItems = topLevelItems.length;

        for (let i = 0; i < totItems; i++) {
            const item = topLevelItems[i];

            if (!hasClass(item, 'has-submenu')) continue;

            const submenu = item.lastElementChild;
            const backTo = submenu.getElementsByClassName('go-to-back')[0];
            // eslint-disable-next-line space-before-function-paren
            submenu.addEventListener(this.eventType, function(e) { e.stopPropagation(); });
            backTo.addEventListener(this.eventType, (e) => {
                e.preventDefault();
                const parent = getParent(e.target, 'LI');
                this.close(parent);
            });

            this.navItems.push(item);
            this.subMenu.push(submenu);
        }

        switch (this.isMobile) {
            case true:
                this.setupSmallScreen();
                break;

            case false:
                this.setupLargeScreen();
                break;
        }
    }


    setupSmallScreen() {
        this.destroyLargeScreen();

        this.toggleNavButton.addEventListener(this.eventType, this.onToggleMenubar);
        this.toggleSearch.addEventListener(this.eventType, this.handlerToggleSearch);

        for (let i = 0; i < this.navItems.length; i++) {
            this.navItems[i].addEventListener(this.eventType, this.onToggleNavItem);
        }
    }


    setupLargeScreen() {
        this.destroySmallScreen();

        for (let i = 0; i < this.navItems.length; i++) {
            this.navItems[i].addEventListener(this.eventType, this.onToggleNavItem);
            this.navItems[i].addEventListener('mouseleave', (e) => this.close(e.currentTarget || e.target));
        }
    }


    destroySmallScreen() {
        this.toggleNavButton.removeEventListener(this.eventType, this.onToggleMenubar);

        for (let i = 0; i < this.navItems.length; i++) {
            this.navItems[i].removeEventListener(this.eventType, this.onToggleNavItem);
            this.subMenu[i].style.setProperty('height', ``);
        }
        this.nav.style.setProperty('top', ``);
        this.nav.style.setProperty('height', ``);
    }


    destroyLargeScreen() {
        for (let i = 0; i < this.navItems.length; i++) {
            this.navItems[i].removeEventListener(this.eventType, this.onToggleNavItem);
        }
    }


    setExpanded(item, state) {
        const target = item.tagName === 'A' || item.tagName === 'BUTTON' ? item : item.firstElementChild;

        if (state) {
            target.setAttribute('aria-expanded', 'true');
        } else {
            target.setAttribute('aria-expanded', 'false');
        }
    }


    open(target) {
        const opener = target.getElementsByTagName('a')[0];
        this.setExpanded(opener, true);
        target.classList.add('expanded');
    }


    close(target) {
        const opener = target.getElementsByTagName('a')[0];
        this.setExpanded(opener, false);
        target.classList.remove('expanded');
    }


    onToggleMenubar(e) { // for mobile
        e.stopPropagation();
        e.preventDefault();

        if (hasClass(this.body, 'nav-open')) {
            this.body.classList.remove('nav-open');

            this.setExpanded(this.toggleNavButton, false);
            //this.toggleNavButton.focus();
        } else {
            this.setNavPositionAndHeight();
            this.body.classList.add('nav-open');
            this.setExpanded(this.toggleNavButton, true);
            //this.toggleNavButton.blur();
            const searchContainer = document.getElementsByClassName('search-container')[0];
            if (searchContainer) {
                searchContainer.classList.remove('open');
            }
        }
    }


    onToggleNavItem(e) {
        e.stopPropagation();
        e.preventDefault();

        const target = e.currentTarget ? e.currentTarget : e.target;

        this.closeExpanded(e);

        hasClass(target, 'expanded') ? this.close(target) : this.open(target);
    }


    handlerToggleSearch() {
        if (hasClass(this.body, 'nav-open')) {
            this.body.classList.remove('nav-open');
        }
    }


    closeExpanded(e) {
        const target = e.currentTarget ? e.currentTarget : e.target;

        const expandedNav = this.navItems.filter((topLevel) => {
            return topLevel.className.indexOf('expanded') > -1 && topLevel !== target;
        });

        const totExpanded = expandedNav.length;

        if (totExpanded === 0) return;

        for (let i = 0; i < totExpanded; i++) {
            this.close(expandedNav[i]);
        }
    }


    hasClass(element, className) {
        const classes = element.className;
        return classes.indexOf(className) === -1 ? false : true;
    }


    setNavPositionAndHeight() { // for mobile
        const hh = this.header.getBoundingClientRect().height;
        this.nav.style.setProperty('top', `${hh}px`);
        this.nav.style.setProperty('height', `${this.screenHeight - hh}px`);

        const totSubmenu = this.subMenu.length;
        for (let index = 0; index < totSubmenu; index++) {
            this.subMenu[index].style.setProperty('height', `${this.screenHeight - hh}px`);
        }
    }


    resize(win) {
        const ww = win.innerWidth;
        const wh = win.innerHeight;

        if (this.screenWidth !== ww || this.screenHeight !== wh) {
            this.screenHeight = wh;
            this.screenWidth = ww;

            if (this.viewportChanged === false && this.isMobile && this.screenWidth >= this.resolutions.medium) {
                this.isMobile = false;
                this.viewportChanged = true;
            }
            if (this.viewportChanged === false && !this.isMobile && this.screenWidth < this.resolutions.medium) {
                this.isMobile = true;
                this.viewportChanged = true;
            }

            if (this.viewportChanged === true) {
                switch (this.isMobile) {
                    case true:
                        this.setupSmallScreen();
                        break;

                    case false:
                        this.setupLargeScreen();
                        break;
                    }

                this.viewportChanged = false;
            }
        }
    }
}

export default Nav;
